import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from './app/common/app-config';
import posthog from 'posthog-js';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const configUrl = environment.production
  ? '/assets/config.json'
  : '/assets/config.local.json';

fetch(configUrl)
  .then((response) => response.json())
  .then((config) => {
    posthog.init(config.postHogKey, {
      api_host: 'https://mon.cybersort.io',
      ui_host: 'https://eu.posthog.com',
      persistence: 'memory',
      capture_pageview: false,
      enable_recording_console_log: true
    });

    if (environment.production) {
      enableProdMode();
    }
    const providers = [
      { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
      { provide: APP_CONFIG, useValue: config }
    ];
    platformBrowserDynamic(providers)
      .bootstrapModule(AppModule)
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  });
