import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { ImportAssetsService } from './import-assets.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  AssetFunctionTypeListResult,
  FileResponse,
  ImportedAssetSetListResult,
  TemplateClient,
  TemplateType
} from '../../../clients/apiClients';
import { SetTreeMode } from '../../../shared/asset.actions';
import { TreeMode } from '../../../shared/treeMode';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SignalrService } from 'src/app/shared/signalr.service';
import { ProgressService } from '../../shared/progress-toast/progress.service';
import { ImportAssetFileUpdatedMessage } from './import-asset-file-updated-message';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { AssetFunctionTypeService } from 'src/app/asset-function-type/asset-function-type.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-import-assets',
  templateUrl: './import-assets.component.html',
  styleUrls: ['./import-assets.component.scss']
})
export class ImportAssetsComponent implements OnInit, OnDestroy {
  private importAssetsService = inject(ImportAssetsService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  private store = inject(Store);
  private router = inject(Router);
  private templateClient = inject(TemplateClient);
  private progressService = inject(ProgressService);
  private assetFunctionTypeService = inject(AssetFunctionTypeService);

  @ViewChild('fileUpload') fileUpload!: FileUpload;
  errorMessage?: string;
  public assetSets?: ImportedAssetSetListResult[];
  private activeRequests: string[] = [];
  public functionTypes?: AssetFunctionTypeListResult[];
  private selectedFunctionTypeId: number | undefined;
  public dialogVisible = false;
  public isTemplate = true;

  constructor() {
    const signalrService = inject(SignalrService);

    signalrService
      .register<string>('ImportAssetFileUpdated')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe(async (data: any) => {
        await this.importFileReady(ImportAssetFileUpdatedMessage.fromJS(data)); // NOSONAR
      });
  }

  showDialog(isTemplate: boolean) {
    this.isTemplate = isTemplate;
    this.dialogVisible = true;
  }

  async importFileReady(result: ImportAssetFileUpdatedMessage) {
    const setId = result.setId;
    const requestId = result.requestId;
    const errorMessage = result.errorMessage;

    if (!this.activeRequests.includes(requestId)) {
      return;
    }

    this.activeRequests = this.activeRequests.filter((o) => o !== requestId);
    this.progressService.complete({ title: 'empty' });

    if (errorMessage) {
      this.messageService.add({
        severity: 'error',
        detail: errorMessage
      });
      return;
    }

    this.openAssetSet(setId);
    this.messageService.add({
      severity: 'success',
      detail: `Asset set uploaded successfully`
    });
  }

  async uploadFiles($event: { files: File[] }) {
    this.progressService.add('Importing file, please wait...');

    try {
      const requestId = await this.importAssetsService.importFile(
        this.selectedFunctionTypeId,
        $event.files[0]
      );
      this.activeRequests.push(requestId);
    } catch {
      this.progressService.complete({ title: 'empty' });
      this.messageService.add({
        severity: 'error',
        detail: `Error importing the file. Please try again or contact the support email.`
      });
    }
    this.fileUpload.clear();
    this.dialogVisible = false;
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(new SetTreeMode(TreeMode.Picker));

    this.functionTypes = await firstValueFrom(
      this.assetFunctionTypeService.list()
    );

    this.importAssetsService.getImportedAssetSets().subscribe((assetSets) => {
      this.assetSets = assetSets;
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetTreeMode(TreeMode.Navigation));
  }

  functionTypeSelectionChanged($event: DropdownChangeEvent) {
    this.selectedFunctionTypeId = $event.value.id;
  }

  async openAssetSet(id: number) {
    await this.router.navigate(['asset', 'import', id]);
  }

  removeSet(id: number) {
    this.confirmationService.confirm({
      key: 'remove-asset-confirm',
      message: `Are you sure that you want to remove the imported set?`,
      accept: async () => {
        this.importAssetsService.removeSet(id).subscribe(() => {
          if (!this.assetSets) return;
          this.assetSets = this.assetSets.filter((o) => o.id != id);
          this.messageService.add({
            severity: 'success',
            detail: `Asset set removed successfully`
          });
        });
      }
    });
  }

  downloadTemplate() {
    this.templateClient
      .getTemplate(TemplateType.Assets, this.selectedFunctionTypeId)
      .subscribe((file: FileResponse) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', file.fileName ?? 'file');
        const href = URL.createObjectURL(file.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
        this.dialogVisible = false;
      });
  }

  protected readonly Number = Number;
}
