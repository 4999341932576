import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { API_BASE_URL } from '../clients/apiClients';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLayoutModule } from './layout/app.layout.module';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { LoginComponent } from './login/login.component';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { NgxsModule } from '@ngxs/store';
import { AppState } from '../shared/app.state';
import { MessageModule } from 'primeng/message';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ImportAssetsComponent } from './import/import-assets/import-assets.component';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ImportApplicationRequirementsComponent } from './import/import-application-requirements/import-application-requirements.component';
import { AuthGuard } from './shared/auth-guard.service';
import { TokenInterceptor } from './shared/token.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { RegisterComponent } from './register/register.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DialogModule } from 'primeng/dialog';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ToastModule } from 'primeng/toast';
import { PendingChangesGuard } from './shared/pending-changes.guard';
import { FocusTrapModule } from 'primeng/focustrap';
import { APP_CONFIG, AppConfig } from './common/app-config';
import { MasterDataService } from '../shared/master-data.service';
import { SignalrService } from './shared/signalr.service';
import { MasterDataState } from '../shared/master-data.state';
import { CardModule } from 'primeng/card';
import { ProgressToastComponent } from './shared/progress-toast/progress-toast.component';
import { DropdownModule } from 'primeng/dropdown';
import { HelpService } from './shared/help.service';
import { ETagInterceptor } from './shared/etag.interceptor';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function signalRInitializer(signalRService: SignalrService) {
  return () => void 0;
}

function masterDataInitializer(masterDataService: MasterDataService) {
  return () => masterDataService.initialize();
}

function helpInitializer(helpService: HelpService) {
  return () => helpService.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ImportAssetsComponent,
    ImportApplicationRequirementsComponent,
    RegisterComponent,
    ForgotPasswordComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppLayoutModule,
    TableModule,
    ChipModule,
    ButtonModule,
    RippleModule,
    TooltipModule,
    NgxsModule.forRoot([AppState, MasterDataState], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    environment.plugins,
    CheckboxModule,
    MenuModule,
    MessageModule,
    PanelModule,
    FileUploadModule,
    ConfirmDialogModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    QRCodeModule,
    DialogModule,
    ToastModule,
    FocusTrapModule,
    CardModule,
    ProgressToastComponent,
    DropdownModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ETagInterceptor,
      multi: true
    },
    {
      provide: API_BASE_URL,
      useFactory: (config: AppConfig) => config.apiUrl,
      deps: [APP_CONFIG]
    },
    ...environment.providers,
    {
      provide: APP_INITIALIZER,
      useFactory: signalRInitializer,
      deps: [SignalrService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: masterDataInitializer,
      deps: [MasterDataService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: helpInitializer,
      deps: [HelpService],
      multi: true
    },
    PendingChangesGuard,
    AuthGuard,
    MessageService,
    ConfirmationService,
    MasterDataService,
    {
      provide: SignalrService,
      useClass: SignalrService
    },
    HelpService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
