import { Component, OnInit, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  layoutService = inject(LayoutService);

  model: MenuItem[] = [];

  ngOnInit() {
    this.model = [];
  }
}
