import { Injectable, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  BehaviorSubject,
  Observable,
  of,
  shareReplay,
  Subject,
  tap,
  throwError
} from 'rxjs';
import {
  AuthenticateClient,
  AuthenticateResult,
  AuthenticationStatus,
  LoginUserCommand,
  ResetPasswordCommand,
  TokenAndRefreshToken
} from '../../clients/apiClients';
import posthog from 'posthog-js';
import { Store } from '@ngxs/store';
import { ResetUser } from '../../shared/app.actions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticateClient = inject(AuthenticateClient);
  private store = inject(Store);
  private router = inject(Router);

  public jwtHelper: JwtHelperService = new JwtHelperService();
  public token: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor() {
    const token: string | null = localStorage.getItem('accessToken');
    this.token.next(token);
  }

  isLoggedIn() {
    const token = localStorage.getItem('accessToken');
    if (!token) return false;
    return !this.jwtHelper.isTokenExpired(token);
  }

  getPermissions(): Observable<string[]> {
    const permissions = localStorage.getItem('permissions');

    if (permissions) {
      return of(JSON.parse(permissions));
    }

    return this.authenticateClient.getUsersPermissions().pipe(
      shareReplay(),
      tap((fetchedPermissions) => {
        localStorage.setItem('permissions', JSON.stringify(fetchedPermissions));
      })
    );
  }

  login(
    email: string,
    password: string,
    otpCode?: string
  ): Observable<AuthenticateResult> {
    const subject = new Subject<AuthenticateResult>();

    const loginObserver = {
      next: async (response: AuthenticateResult | null) => {
        if (response == null) {
          subject.error('Unknown error occured');
          subject.complete();
          return;
        }

        if (response.status == AuthenticationStatus.Success) {
          const token = response.token;
          const refreshToken = response.refreshToken;

          if (!token || !refreshToken)
            throw Error('Token or refresh token is empty');

          localStorage.setItem('accessToken', token);
          localStorage.setItem('refreshToken', refreshToken);
          this.token.next(token);
        }
        subject.next(response);
        subject.complete();
      },
      error: (error: string) => {
        subject.error(error);
        subject.complete();
      }
    };

    this.authenticateClient
      .authenticateWithRefresh(
        LoginUserCommand.fromJS({
          email: email,
          password: password,
          otpCode: otpCode
        })
      )
      .subscribe(loginObserver);

    return subject;
  }

  forgotPassword(email: string) {
    return this.authenticateClient.forgotPassword(email);
  }

  resetPassword(token: string, email: string, password: string) {
    return this.authenticateClient.resetPassword(
      ResetPasswordCommand.fromJS({
        email: email,
        token: token,
        password: password
      })
    );
  }

  refreshToken() {
    const token: string | null = localStorage.getItem('accessToken');
    const refreshToken: string | null = localStorage.getItem('refreshToken');

    if (!token || !refreshToken) {
      return throwError(() => 'Unknown error occured');
    }

    return this.authenticateClient
      .refreshAuthentication(
        TokenAndRefreshToken.fromJS({ token, refreshToken })
      )
      .pipe(
        tap((response) => {
          if (!response) return;
          const newToken = response.token;
          const newRefreshToken = response.refreshToken;
          localStorage.setItem('accessToken', newToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          this.token.next(newToken);
        })
      );
  }
  logout() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) return;

    this.authenticateClient.logout(refreshToken).subscribe(async () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('permissions');
      this.token.next(null);
      this.store.dispatch(new ResetUser());
      posthog.capture('logout');
      posthog.reset();
      await this.router.navigate(['login']);
    });
  }

  removeTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('permissions');
    this.token.next(null);
  }
}
