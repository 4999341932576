import { Injectable, inject } from '@angular/core';
import { ReportClient, ReportStatus } from '../../clients/apiClients';
import { firstValueFrom } from 'rxjs';
import { ProgressService } from '../shared/progress-toast/progress.service';
import { SignalrService } from '../shared/signalr.service';

@Injectable({ providedIn: 'root' })
export class ReportService {
  private reportClient = inject(ReportClient);
  private progressService = inject(ProgressService);

  private activeRequests: string[] = [];
  constructor() {
    const signalrService = inject(SignalrService);

    signalrService
      .register<string>('updateReportStatus')
      .subscribe(async (data) => {
        await this.reportReady(data); // NOSONAR
      });

    signalrService.reconnected.subscribe(
      async () => await this.reconnected() // NOSONAR
    );
  }

  async reconnected() {
    for (const requestId of this.activeRequests) {
      const reportStatus = await firstValueFrom(
        this.reportClient.getStatus(requestId)
      );
      if (reportStatus.status == ReportStatus.Completed) {
        await this.reportReady(requestId);
      }
    }
  }

  async export(assetId: string) {
    this.progressService.add('The report is being generated, please wait...');
    const reportRequestId = await firstValueFrom(
      this.reportClient.generate(assetId)
    );
    this.activeRequests.push(reportRequestId);
  }

  async reportReady(reportRequestId: string) {
    if (!this.activeRequests.includes(reportRequestId)) {
      return;
    }

    this.activeRequests = this.activeRequests.filter(
      (o) => o !== reportRequestId
    );
    const file = await firstValueFrom(
      this.reportClient.download(reportRequestId)
    );

    this.progressService.complete({ title: 'empty' });
    const blob = new Blob([file.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    window.open(url);
  }

  async exportMultiple(assetIds: string[]) {
    this.progressService.add('The report is being generated, please wait...');
    const reportRequestId = await firstValueFrom(
      this.reportClient.generateMultiple(assetIds)
    );
    this.activeRequests.push(reportRequestId);
  }
}
