import { Component, OnInit } from '@angular/core';
import { AppState, AppStateModel } from '../../shared/app.state';
import { map, Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  loginDisplay$?: Observable<boolean>;
  @Select(AppState) app$!: Observable<AppStateModel>;

  ngOnInit(): void {
    this.loginDisplay$ = this.app$.pipe(
      map((value) => value.profile?.email !== undefined)
    );
  }
}
