import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  UrlSegment
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import {
  AuthenticateClient,
  TokenAndRefreshToken
} from '../../clients/apiClients';
import { Store } from '@ngxs/store';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private router = inject(Router);
  private authenticateClient = inject(AuthenticateClient);
  private store = inject(Store);
  private authService = inject(AuthService);

  public jwtHelper: JwtHelperService = new JwtHelperService();

  async canActivate(next: ActivatedRouteSnapshot) {
    const token = localStorage.getItem('accessToken');

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return this.checkUserLogin(next);
    }

    const isRefreshSuccess = await this.refreshingTokens(token);

    if (isRefreshSuccess && (await this.checkUserLogin(next))) return true;
    await this.router.navigate(['login']);
    return false;
  }

  async checkUserLogin(
    route: ActivatedRouteSnapshot | Route
  ): Promise<boolean> {
    if (this.authService.isLoggedIn()) {
      const permissions = await firstValueFrom(
        this.authService.getPermissions()
      );
      if (
        !route.data?.permission ||
        permissions.includes(route.data.permission)
      )
        return true;
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async canLoad(route: Route, segments: UrlSegment[]) {
    const token = localStorage.getItem('accessToken');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return this.checkUserLogin(route);
    }

    const isRefreshSuccess = await this.refreshingTokens(token);
    if (isRefreshSuccess && (await this.checkUserLogin(route))) return true;
    await this.router.navigate(['login']);
    return false;
  }

  private async refreshingTokens(token: string | null): Promise<boolean> {
    const refreshToken: string | null = localStorage.getItem('refreshToken');

    if (!token || !refreshToken) {
      return false;
    }

    let isRefreshSuccess: boolean;
    try {
      const response = await lastValueFrom(
        this.authenticateClient.refreshAuthentication(
          TokenAndRefreshToken.fromJS({ token, refreshToken })
        )
      );
      if (!response) return false;

      const newToken = response.token;
      const newRefreshToken = response.refreshToken;
      localStorage.setItem('accessToken', newToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      this.authService.token.next(newToken);
      isRefreshSuccess = true;
    } catch {
      isRefreshSuccess = false;
    }
    return isRefreshSuccess;
  }
}
