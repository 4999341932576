import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LayoutService } from '../layout/service/app.layout.service';
import { AssetShortcutClient } from '../../clients/apiClients';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import posthog from 'posthog-js';
import { AssetService } from './asset.service';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent {
  layoutService = inject(LayoutService);
  private shortcutClient = inject(AssetShortcutClient);
  private router = inject(Router);
  private messageService = inject(MessageService);
  private assetService = inject(AssetService);

  constructor() {
    const titleService = inject(Title);

    titleService.setTitle('Assets - cybersort');
  }

  get isInterfacePage() {
    return this.router.url.includes('requirement-interface-links');
  }

  get styleClass() {
    if (this.isMoving || this.isDuplicating) {
      return 'tree-container-highlight';
    }
    return '';
  }

  get isMoving() {
    return this.assetService.isMoving;
  }

  get isDuplicating() {
    return this.assetService.isDuplicating;
  }

  shortcutChanged($event: Event) {
    const elm = $event.target as HTMLInputElement;
    const val = elm.value;

    posthog.capture('asset_shortcut', { value: val });

    this.shortcutClient.get(elm.value).subscribe((result) => {
      elm.value = '';

      if (result.assetId) {
        this.router.navigate([`/asset/${result.assetId}`]);
      } else {
        this.messageService.add({
          severity: 'error',
          detail: "Asset with UUID or urn '" + val + "' found"
        });
      }
    });
  }
}
