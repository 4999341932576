<div class="flex flex-row panel-container flex-wrap mb-2">
  <div class="flex w-full mb-1">
    <div class="flex-1">
      <h3>Search</h3>
    </div>
    <div>
      <button
        [disabled]="
          this.tableSelectionService.selection.length == 0 || isGeneratingReport
        "
        data-test="create-report-button"
        icon="pi pi-upload"
        label="Create report"
        (click)="createReport()"
        pButton
      ></button>
    </div>
  </div>
  <div *ngIf="functionTypes$ | async as functionTypes" class="w-full">
    <div class="flex flex-wrap flex-grow-1">
      <p-dropdown
        #functionTypeSelector
        data-test="function-type-selector"
        [options]="functionTypes"
        styleClass="w-20rem"
        optionLabel="name"
        placeholder="Function type"
        (onChange)="functionTypeSelectionChanged($event)"
      >
      </p-dropdown>
    </div>
    <div
      class="flex mt-3 border-round-3xl w-full h-3rem p-1 filters-container"
      data-test="filters-container"
    >
      <ng-container *ngFor="let filter of filterService.filters | async">
        <p-chip
          [label]="filter.text"
          styleClass="function-type-chip mr-2"
          [removable]="true"
          (onRemove)="filterRemoved(filter)"
        ></p-chip>
      </ng-container>
    </div>
    <div class="mt-3">
      <p-button
        [plain]="true"
        [text]="true"
        label="Clear filters"
        (click)="this.filterService.clearFilters()"
      ></p-button>
    </div>
  </div>
</div>
<div class="panel-container">
  <p-table
    data-test="asset-list"
    [(selection)]="this.tableSelectionService.selection"
    *ngIf="assetList$ | async as assetList"
    [loading]="(isLoading$ | async)!"
    loadingIcon="pi pi-spin pi-spinner"
    [value]="assetList.assets"
    styleClass="p-datatable-striped"
    [tableStyle]="{ 'min-width': '50rem' }"
    dataKey="id"
    sortField="name"
    #table
    [paginator]="true"
    [rowsPerPageOptions]="[25, 50, 200]"
    [rows]="25"
  >
    <ng-template pTemplate="caption">
      <span data-test="result-count">
        <ng-container *ngIf="this.tableSelectionService.selection.length == 0">
          {{ assetList.assets.length }} results
        </ng-container>
        <ng-container *ngIf="this.tableSelectionService.selection.length != 0">
          {{ this.tableSelectionService.selection.length }} /
          {{ assetList.assets.length }} assets selected (<a
            data-test="clear-selection-button"
            href="javascript:void(0)"
            (click)="this.tableSelectionService.selection = []"
            >Clear selection</a
          >)
        </ng-container>
      </span>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th scope="col" pSortableColumn="name">
          Name<p-sortIcon field="name"></p-sortIcon>
        </th>
        <th scope="col" pSortableColumn="urn">
          Urn<p-sortIcon field="urn"></p-sortIcon>
        </th>
        <th scope="col" pSortableColumn="functionType">
          Function type<p-sortIcon field="functionType"></p-sortIcon>
        </th>
        <th scope="col">Edit</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-asset>
      <tr
        (click)="this.tableSelectionService.onRowClick($event, asset)"
        class="cursor-pointer"
      >
        <td>
          <p-tableCheckbox [value]="asset"></p-tableCheckbox>
        </td>
        <td>{{ asset.name }}</td>
        <td>{{ asset.urn }}</td>
        <td>{{ asset.functionType }}</td>
        <td>
          <a
            data-test="edit-button"
            [routerLink]="['/', 'asset', asset.id]"
            target="_blank"
            (click)="$event.stopPropagation()"
            ><i class="pi pi-file-edit"></i
          ></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<progress-toast></progress-toast>
