<div
  class="align-items-center flex justify-content-center lg:px-8 md:px-6 px-4 py-8 surface-ground ng-star-inserted"
>
  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6 max-w-30rem">
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">
        <img src="assets/layout/images/logo.svg" alt="Cybersort" class="logo" />
      </div>
    </div>
    <div *ngIf="errorMessage" class="mb-3">
      <p-message
        severity="danger"
        styleClass="w-full"
        [text]="errorMessage"
      ></p-message>
    </div>
    <div>
      <form [formGroup]="loginForm" id="loginForm">
        <label class="block te xt-900 font-medium mb-2" for="email"
          >Email</label
        >
        <input
          class="w-full mb-3"
          data-attr="email"
          data-test="email"
          formControlName="email"
          autocomplete="username"
          id="email"
          pInputText
          type="text"
        />
        <label class="block text-900 font-medium mb-2" for="password"
          >Password</label
        >
        <input
          class="w-full mb-3"
          data-attr="password"
          data-test="password"
          autocomplete="password"
          formControlName="password"
          id="password"
          pInputText
          type="password"
        />
        <label
          *ngIf="otpNeeded"
          class="block text-900 font-medium mb-2"
          for="optCode"
          >Authenticator code</label
        >
        <input
          *ngIf="otpNeeded"
          autofocus
          class="w-full mb-3"
          autocomplete="one-time-code"
          data-test="otpCode"
          formControlName="otpCode"
          id="optCode"
          maxlength="6"
          pInputText
        />

        <div class="flex align-items-center justify-content-between mb-6">
          <a
            (click)="this.showForgotPassword()"
            class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
            >Forgot password?</a
          >
        </div>

        <p-button
          (click)="login()"
          data-attr="login"
          [disabled]="loginForm.invalid || isLoading"
          styleClass="w-full"
          data-test="login"
          icon="pi pi-user"
          label="Sign In"
          [loading]="isLoading"
          loadingIcon="pi pi-spinner"
          pRipple
          type="submit"
        ></p-button>
      </form>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="forgotPassword"
  [modal]="true"
  header="Forgot password"
  styleClass="w-30rem"
>
  <form [formGroup]="forgotPasswordForm">
    <label class="block te xt-900 font-medium mb-2" for="email">Email</label>
    <input
      autocomplete="username"
      class="w-full mb-3"
      data-test="forgot_email"
      formControlName="email"
      id="forgot_email"
      pInputText
      type="text"
    />
  </form>
  <ng-template pTemplate="footer">
    <button
      (click)="sendForgotPassword()"
      [disabled]="forgotPasswordForm.invalid"
      class="w-full"
      label="Send recovery link"
      pButton
      type="button"
    ></button>
  </ng-template>
</p-dialog>
