<div
  class="align-items-center flex justify-content-center lg:px-8 md:px-6 px-4 py-8 surface-ground ng-star-inserted"
>
  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6 max-w-30rem">
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">
        <img src="assets/layout/images/logo.svg" alt="Cybersort" class="logo" />
      </div>
    </div>
    <div>
      <form [formGroup]="registerForm">
        <label class="block text-900 font-medium mb-2">Email</label>
        {{ this.registerForm.value.email }}
        <label class="block text-900 font-medium mb-2" for="password"
          >Password</label
        >
        <input
          formControlName="password"
          class="w-full mb-3"
          autocomplete="new-password"
          data-test="password"
          id="password"
          pInputText
          type="password"
        />
        <ng-container *ngIf="otpUrl">
          <label class="block text-900 font-medium mb-2"
            >Two-factor authentication
            <i
              class="pi pi-question-circle"
              pTooltip="Scan the QR code for example with Google authenticator or Microsoft authenticator"
            ></i
          ></label>
          <div style="text-align: center">
            <qrcode
              [errorCorrectionLevel]="'M'"
              [qrdata]="otpUrl"
              [width]="256"
            ></qrcode>
          </div>

          <label class="block text-900 font-medium mb-2"
            >Authenticator code</label
          >
          <input
            class="w-full mb-3"
            autofocus
            formControlName="otpCode"
            maxlength="6"
            pInputText
            placeholder="000000"
          />
        </ng-container>
        <button
          (click)="this.otpUrl ? register() : createOtpCode()"
          [disabled]="registerForm.invalid || loading"
          class="w-full"
          data-test="register"
          icon="pi pi-user"
          label="Register"
          pButton
          pRipple
        ></button>
        <br /><br />
        <p-message
          *ngIf="errors"
          [escape]="false"
          [text]="errors"
          severity="danger"
          styleClass="error-list"
        ></p-message>
      </form>
    </div>
  </div>
</div>
