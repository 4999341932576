import { Injectable, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard {
  private confirmationService = inject(ConfirmationService);

  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    const p = new Subject<boolean>();

    if (component.canDeactivate()) return true;

    this.confirmationService.confirm({
      key: 'deactivateConfirmation',
      message:
        'You have unsaved changes, are you sure you want to navigate away?',
      accept: () => {
        p.next(true);
        p.complete();
      },
      reject: () => {
        p.next(false);
        p.complete();
      }
    });

    return p;
  }
}
