import { EventEmitter, Injectable, inject } from '@angular/core';
import { AssetClient, AssetMovedResult } from '../../clients/apiClients';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetMoveService {
  private assetClient = inject(AssetClient);

  public assetMoved$ = new EventEmitter<AssetMovedResult>();
  public siblingCountChanged$ = new BehaviorSubject<number>(-1);

  public async moveUp(assetId: string) {
    const val = await firstValueFrom(this.assetClient.moveUp(assetId));
    this.assetMoved$.emit(val);
  }

  public async moveDown(assetId: string) {
    const val = await firstValueFrom(this.assetClient.moveDown(assetId));
    this.assetMoved$.emit(val);
  }
}
