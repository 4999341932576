import { Injectable } from '@angular/core';
import { FilterBase } from './filters/filterBase';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filters$: BehaviorSubject<FilterBase[]>;

  constructor() {
    this.filters$ = new BehaviorSubject<FilterBase[]>([]);
  }

  get filters() {
    return this.filters$.asObservable();
  }

  addFilter(filterBase: FilterBase) {
    const currentFilters = this.filters$.getValue();
    this.filters$.next([...currentFilters, filterBase]);
  }

  removeFilter(filter: FilterBase) {
    const currentFilters = this.filters$.getValue();
    this.filters$.next(currentFilters.filter((f) => f !== filter));
  }

  clearFilters() {
    this.filters$.next([]);
  }
}
