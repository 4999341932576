import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import {
  AssetFunctionTypeClient,
  AssetFunctionTypeResult,
  AssetListResult,
  GetAssetListClient,
  GetAssetListQueryResult
} from '../../clients/apiClients';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { FilterService } from './filtering/filter.service';
import { FunctionTypeFilter } from './filtering/filters/functionTypeFilter';
import { FilterBase } from './filtering/filters/filterBase';
import { Table } from 'primeng/table';
import { ReportService } from '../asset/report.service';
import { ProgressService } from '../shared/progress-toast/progress.service';
import posthog from 'posthog-js';
import { TableSelectionService } from '../shared/table-selection.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy {
  private reportService = inject(ReportService);
  filterService = inject(FilterService);
  private progressService = inject(ProgressService);

  public functionTypes$: Observable<AssetFunctionTypeResult[]>;
  public assetList$: Observable<GetAssetListQueryResult | null>;

  @ViewChild('table')
  table!: Table;

  @ViewChild('functionTypeSelector')
  functionTypeSelector!: Dropdown;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isGeneratingReport: boolean = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  public tableSelectionService: TableSelectionService<AssetListResult>;
  constructor() {
    const functionTypeClient = inject(AssetFunctionTypeClient);
    const getAssetListClient = inject(GetAssetListClient);

    this.tableSelectionService = new TableSelectionService<AssetListResult>();

    this.functionTypes$ = combineLatest([this.filterService.filters]).pipe(
      map(([filters]) => this.getFunctionTypeFilters(filters)),
      switchMap((filters) =>
        functionTypeClient
          .getOptions()
          .pipe(map((arr) => arr.filter((o) => !filters.includes(o.id))))
      )
    );

    this.assetList$ = combineLatest([this.filterService.filters]).pipe(
      tap(() => {
        this.isLoading$.next(true);
        this.tableSelectionService.selection = [];
      }),
      switchMap(([filters]) =>
        getAssetListClient.get(this.getFunctionTypeFilters(filters))
      ),
      tap(() => this.isLoading$.next(false))
    );

    this.progressService.itemCreated
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.isGeneratingReport = true;
      });
    this.progressService.itemDone
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.isGeneratingReport = false;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  functionTypeSelectionChanged($event: DropdownChangeEvent) {
    const filter = new FunctionTypeFilter($event.value.name, $event.value);
    this.filterService.addFilter(filter);
    this.functionTypeSelector.selectedOption = null;
    posthog.capture('function_type_selected');
  }

  filterRemoved(filter: FilterBase) {
    this.filterService.removeFilter(filter);
  }

  private getFunctionTypeFilters(filters: FilterBase[]): number[] {
    const functionTypeFilters = filters.filter(
      (f) => f.type === 'functionType'
    ) as FunctionTypeFilter[];
    return functionTypeFilters.map((f) => f.value.id);
  }

  async createReport() {
    const selectedIds = this.getSelectedIds();
    const sortedIds = this.getSortedIds(selectedIds);
    posthog.capture('report_created');
    await this.reportService.exportMultiple(sortedIds);
  }

  private getSelectedIds() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.table.selection.map((o: any) => o.id);
  }

  private getSortedIds(selectedIds: string[]) {
    return this.table.value
      .filter((o) => selectedIds.includes(o.id))
      .map((o) => o.id);
  }
}
