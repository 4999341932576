import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CreateOtpKeyCommand,
  CreateOtpKeyForUserClient,
  CreateUserCommand,
  CreateUserFromInviteClient
} from '../../clients/apiClients';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from '../shared/auth.service';
import posthog from 'posthog-js';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  private createOtpKeyForUserClient = inject(CreateOtpKeyForUserClient);
  private createUserFromInviteClient = inject(CreateUserFromInviteClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private messageService = inject(MessageService);

  public errors?: string;

  registerForm = this.fb.group({
    key: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required]
      }
    ),
    otpKey: new FormControl(''),
    otpCode: new FormControl('')
  });
  public otpUrl?: string = undefined;
  public loading = false;

  constructor() {
    this.route.queryParamMap.subscribe((map) => {
      const email = map.get('email');
      const key = map.get('key');

      if (!email || !key) {
        this.messageService.add({
          severity: 'error',
          detail: 'The invite link is invalid'
        });
        return;
      }

      this.registerForm.patchValue({ email: email, key: key });
    });
  }

  register() {
    const val = this.registerForm.getRawValue();
    if (!val.key || !val.email || !val.password) {
      return;
    }

    this.loading = true;

    const createUserObserver = {
      next: async () => {
        if (!val.key || !val.email || !val.otpCode || !val.password) {
          return;
        }
        this.authService
          .login(val.email, val.password, val.otpCode)
          .subscribe(async () => {
            await this.router.navigate(['']);
            this.messageService.add({
              severity: 'success',
              detail: 'Registration successful!'
            });
            posthog.capture('register', { email: val.email });
            this.loading = false;
          });
      },
      error: (error: string[]) => {
        this.errors = error.join('<br /><br />');
        this.loading = false;
      }
    };

    this.createUserFromInviteClient
      .createUserFromInvite(
        CreateUserCommand.fromJS({
          email: val.email,
          password: val.password,
          otpKey: val.otpKey,
          otpCode: val.otpCode,
          invite: val.key
        })
      )
      .subscribe(createUserObserver);
  }

  createOtpCode() {
    const val = this.registerForm.value;
    if (!val.key || !val.email || !val.password) {
      return;
    }

    this.loading = true;

    const otpKeyObserver = {
      next: async (otpKey: string) => {
        if (!otpKey) return;

        this.errors = '';
        this.registerForm.patchValue({ otpKey: otpKey });
        this.otpUrl = `otpauth://totp/cybersort.io:${this.registerForm.value.email}?secret=${otpKey}&issuer=cybersort.io`;
        this.registerForm.controls['password'].disable();
        this.loading = false;
      },
      error: (error: string[]) => {
        this.errors = error.join('<br /><br />');
        this.loading = false;
      }
    };

    this.createOtpKeyForUserClient
      .createOtpKeyForUser(
        CreateOtpKeyCommand.fromJS({
          username: val.email,
          password: val.password
        })
      )
      .subscribe(otpKeyObserver);
  }
}
