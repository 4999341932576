import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableSelectionService<T> {
  public selection: T[] = [];

  onRowClick(event: MouseEvent, row: T) {
    if (this.isCheckbox(event)) return;

    if (this.selection.includes(row)) {
      this.selection = this.selection.filter((val) => val !== row);
    } else {
      this.selection = [...this.selection, row];
    }
  }

  private isCheckbox(event: MouseEvent) {
    const elm = event.target as HTMLElement;

    if (elm.classList.contains('p-checkbox-box')) return true;
    return elm.tagName.toLowerCase() === 'svg';
  }
}
