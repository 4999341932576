import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import {
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  Subscription
} from 'rxjs';
import { TreeNode } from 'primeng/api';
import { Router } from '@angular/router';
import {
  AssetResult,
  GetAssetHierarchyResponse
} from '../../../clients/apiClients';
import { Select, Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { AssetService } from '../asset.service';
import { TreeMode } from 'src/shared/treeMode';
import { CollapseAsset, ResetAsset } from '../../../shared/asset.actions';
import { AssetMoveService } from '../asset-move.service';
import { AuthService } from '../../shared/auth.service';
import { Tree, TreeNodeCollapseEvent, TreeNodeExpandEvent } from 'primeng/tree';
import posthog from 'posthog-js';

@Component({
  selector: 'app-assets-tree',
  templateUrl: './assets-tree.component.html',
  styleUrls: ['./assets-tree.component.scss']
})
export class AssetsTreeComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private store = inject(Store);
  private assetService = inject(AssetService);
  private assetMoveService = inject(AssetMoveService);
  private authService = inject(AuthService);

  public selection?: TreeNode;

  @ViewChild('tree')
  tree!: Tree;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Select((state: any) => state.asset.selectedAsset)
  public selectedAsset$!: Observable<AssetResult>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Select((state: any) => state.asset.treeMode)
  private treeMode$!: Observable<TreeMode>;
  public assetTree!: TreeNode[];
  private assetSubscription?: Subscription;

  private assetAddedSubscription?: Subscription;
  private assetRemovedSubscription?: Subscription;
  private assetUpdatedSubscription?: Subscription;
  private selectNodeSubscription?: Subscription;
  private treeMode: TreeMode = TreeMode.Navigation;
  private savedSelection: TreeNode | undefined;
  private rootAddedSubscription?: Subscription;
  private assetParentChangedSubscription?: Subscription;
  private assetMovedSubscription?: Subscription;
  public isSuperAdmin$: Observable<boolean> = this.authService
    .getPermissions()
    .pipe(map((permissions) => permissions.includes('AccessAll')));

  async ngOnInit(): Promise<void> {
    this.assetUpdatedSubscription = this.assetService.assetUpdated$.subscribe(
      (asset) => {
        const node = this.getNodeWithKey(asset.id, this.assetTree);
        if (node) {
          node.label = asset.name;
        }
      }
    );

    this.selectNodeSubscription = this.assetService.selectAsset$.subscribe(
      async (id) => {
        const node = this.getNodeWithKey(id, this.assetTree);
        if (node === undefined) {
          return;
        }

        if (node.parent === undefined || node.parent.children === undefined) {
          return;
        }

        this.selection = node;
        this.assetMoveService.siblingCountChanged$.next(
          node.parent.children.length
        );
        await this.router.navigate(['asset', id]);
      }
    );

    this.treeMode$.subscribe((treeMode) => {
      this.treeMode = treeMode;

      if (
        treeMode == TreeMode.Picker ||
        treeMode == TreeMode.PickerWithoutChildren
      ) {
        this.savedSelection = this.selection;
        this.selection = undefined;

        if (
          this.savedSelection != undefined &&
          this.savedSelection.key === undefined
        )
          throw new Error('Selected node doesnt have a key');

        if (treeMode == TreeMode.PickerWithoutChildren && this.savedSelection) {
          this.setNodeSelectable(this.savedSelection, false);
        }
      }

      if (treeMode == TreeMode.Navigation) {
        this.selection = this.savedSelection;
        if (this.savedSelection?.key !== undefined)
          this.setNodeSelectable(this.savedSelection, true);
        this.savedSelection = undefined;
      }
    });

    this.assetAddedSubscription = this.assetService.assetAdded$.subscribe(
      (asset) => {
        const parent = this.getNodeWithKey(
          asset.parentId.replace(/-/g, ''),
          this.assetTree
        );
        if (parent?.children == undefined)
          throw new Error(`Parent {asset.parentId} not found`);

        parent.expanded = true;

        const child = {
          label: asset.name,
          key: asset.id.replace(/-/g, ''),
          parent: parent,
          children: []
        };
        parent.children.push(child);
        this.selection = child;
      }
    );

    this.rootAddedSubscription = this.assetService.rootAdded$.subscribe(
      (asset) => {
        const rootNode = this.assetTree.find((o) => o.parent == undefined);

        const newRoot: TreeNode = {
          label: asset.name,
          key: asset.id.replace(/-/g, ''),
          expanded: true,
          children: []
        };

        if (rootNode && newRoot.children) {
          newRoot.children.push(rootNode);
          rootNode.parent = newRoot;
        }
        this.assetTree.splice(0, 1, newRoot);
        this.selection = newRoot;
      }
    );

    this.assetRemovedSubscription = this.assetService.assetRemoved$.subscribe(
      async (asset) => {
        const parent = this.getNodeWithKey(
          asset.parentId!.replace(/-/g, ''),
          this.assetTree
        );

        if (!parent)
          throw new Error(
            `Parent asset {asset.parentId} for asset {asset.id} cannot be found`
          );

        const index = parent.children?.findIndex(
          (o) => o.key == asset.id.replace(/-/g, '')
        );

        if (parent?.children == undefined || index == undefined) return;

        parent.children.splice(index, 1);
        this.assetMoveService.siblingCountChanged$.next(parent.children.length);
        this.selection = parent;
        await this.router.navigate([
          'asset',
          asset.parentId!.replace(/-/g, '')
        ]);
      }
    );

    this.assetParentChangedSubscription =
      this.assetService.assetParentChanged$.subscribe(async (asset) => {
        const node = this.getNodeWithKey(asset.id, this.assetTree);

        if (node?.parent?.children === undefined)
          throw new Error('Parent for node cannot be found');

        node.parent.selectable = true;
        node.selectable = true;
        node.label = asset.name;
        node.parent.children = node.parent.children.filter((o) => o != node);

        const newParent = this.getNodeWithKey(asset.parentId!, this.assetTree);

        if (newParent?.children === undefined)
          throw new Error('New parent cannot be found');

        newParent.children.push(node);
        node.parent = newParent;
        this.assetMoveService.siblingCountChanged$.next(
          node.parent.children!.length
        );

        let currentNode: TreeNode | undefined = node.parent;
        while (currentNode !== undefined) {
          currentNode.expanded = true;
          currentNode = currentNode.parent;
        }
        this.selection = node;
      });

    this.assetMovedSubscription = this.assetMoveService.assetMoved$.subscribe(
      (assetMoved) => {
        const asset = this.getNodeWithKey(assetMoved.id, this.assetTree);
        if (!asset) return;

        const parent = asset.parent;
        if (!parent?.children) return;

        const index = parent?.children?.indexOf(asset);
        if (index === undefined) return;

        if (index < assetMoved.order) {
          this.swapElements(parent.children, index, index + 1);
        } else {
          this.swapElements(parent.children, index, index - 1);
        }
      }
    );

    const expandedAssets = this.store.selectSnapshot<string[]>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state: any) => state.asset.expandedAssets
    );
    this.assetTree = await firstValueFrom(
      this.assetService.getHierarchy().pipe(
        map((items) => {
          const result: TreeNode[] = [];
          items.forEach((item) => {
            const node = {
              label: item.name,
              key: item.assetId.replace(/-/g, ''),
              expanded: true,
              children: []
            };
            result.push(node);
            this.addChildren(node, item.children, expandedAssets);
          });
          return result;
        }),
        shareReplay()
      )
    );

    this.assetSubscription = this.selectedAsset$
      .pipe(filter((o) => o !== undefined))
      .subscribe((asset) => {
        if (asset == undefined || this.assetTree == undefined) return;
        const node = this.getNodeWithKey(asset.id, this.assetTree);
        if (node !== undefined) {
          if (node.parent && node.parent.children) {
            this.assetMoveService.siblingCountChanged$.next(
              node.parent.children.length
            );
          }
          this.selection = node;
          this.savedSelection = this.selection;

          let currentNode: TreeNode | undefined = node.parent;
          while (currentNode !== undefined) {
            currentNode.expanded = true;
            currentNode = currentNode.parent;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.assetAddedSubscription?.unsubscribe();
    this.assetRemovedSubscription?.unsubscribe();
    this.assetUpdatedSubscription?.unsubscribe();
    this.rootAddedSubscription?.unsubscribe();
    this.assetParentChangedSubscription?.unsubscribe();
    this.assetSubscription?.unsubscribe();
    this.assetMovedSubscription?.unsubscribe();
    this.selectNodeSubscription?.unsubscribe();
    this.store.dispatch(new ResetAsset());
  }

  get isMoving() {
    return this.assetService.isMoving;
  }

  get isDuplicating() {
    return this.assetService.isDuplicating;
  }

  private setNodeSelectable(node: TreeNode, selectable: boolean) {
    if (node.parent !== undefined) {
      node.parent.selectable = selectable;
    }
    node.selectable = selectable;
    node.children?.forEach((childNode) => {
      childNode.selectable = selectable;
      this.setChildrenSelectable(childNode, selectable);
    });
  }

  private setChildrenSelectable(node: TreeNode, selectable: boolean) {
    node.children?.forEach((o) => {
      o.selectable = selectable;
      this.setChildrenSelectable(o, selectable);
    });
  }

  getNodeWithKey(key: string, nodes: TreeNode[]): TreeNode | undefined {
    for (const node of nodes) {
      if (node.key === key.replace(/-/g, '')) {
        return node;
      }

      if (node.children) {
        const matchedNode = this.getNodeWithKey(key, node.children);
        if (matchedNode) {
          return matchedNode;
        }
      }
    }
    return undefined;
  }

  addChildren(
    node: TreeNode,
    children: GetAssetHierarchyResponse[],
    expandedNodes: string[]
  ) {
    node.children = children.map((item) => {
      const childNode = {
        label: item.name,
        key: item.assetId.replace(/-/g, ''),
        parent: node,
        // expanded: expandedNodes.includes(item.assetId.replace(/-/g, '')),
        children: []
      };
      this.addChildren(childNode, item.children, expandedNodes);
      return childNode;
    });
  }

  async nodeSelect($event: { node: TreeNode }) {
    posthog.capture('asset_node_selected');
    if (this.treeMode == TreeMode.Navigation) {
      const navigationResult = await this.router.navigate([
        'asset',
        $event.node.key
      ]);
      if (!navigationResult) {
        this.selection = this.savedSelection;
      } else if ($event.node.parent) {
        this.assetMoveService.siblingCountChanged$.next(
          $event.node.parent.children!.length
        );
      }
      this.savedSelection = this.selection;
    } else {
      this.assetService.nodePicked$.emit($event.node);
    }
  }

  async nodeUnselect($event: { node: TreeNode }) {
    if (this.treeMode == TreeMode.Navigation) return;

    posthog.capture('asset_node_unselected');
    this.assetService.nodeUnpicked$.emit($event.node);
  }

  nodeCollapse($event: TreeNodeCollapseEvent) {
    this.collapseChildren($event.node);
    posthog.capture('asset_node_collapsed');
    this.store.dispatch(new CollapseAsset($event.node.key!));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  nodeExpand($event: TreeNodeExpandEvent) {
    posthog.capture('asset_node_expanded');
  }

  collapseChildren(node: TreeNode) {
    node.children?.forEach((childNode) => {
      this.store.dispatch(new CollapseAsset(childNode.key!));
      childNode.expanded = false;
      this.collapseChildren(childNode);
    });
  }

  async createRootAsset() {
    await this.router.navigate(['asset', 'root']);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  swapElements(array: Array<any>, index1: number, index2: number): void {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
  }
}
