import { FilterBase } from './filterBase';
export abstract class Filter<T> extends FilterBase {
  protected constructor(
    text: string,
    type: string,
    public value: T
  ) {
    super(text, type);
  }
}
