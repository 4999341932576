<div
  [ngClass]="{
    'asset-container-min': layoutService.showHeader && isInterfacePage,
    'asset-container': layoutService.showHeader && !isInterfacePage,
    'asset-container-no-header-min':
      !layoutService.showHeader && isInterfacePage,
    'asset-container-no-header': !layoutService.showHeader && !isInterfacePage,
    'is-duplicating': isDuplicating,
    'is-moving': isMoving
  }"
  class="flex justify-content-start gap-2"
>
  <p-card class="flex flex-column tree-container" [styleClass]="styleClass">
    <ng-template pTemplate="header" *ngIf="!isInterfacePage">
      <span class="p-input-icon-left w-full search-icon">
        <i class="pi pi-external-link"></i>
        <input
          pInputText
          class="shortcut-input w-full h-4rem"
          (change)="shortcutChanged($event)"
          type="text"
          placeholder="Asset UUID or Urn"
        />
      </span>
    </ng-template>

    <app-assets-tree class="flex-1"></app-assets-tree>
  </p-card>
  <p-card
    styleClass="h-full overflow-hidden"
    class="flex-grow-1 overflow-x-auto pb-1"
  >
    <router-outlet></router-outlet>
  </p-card>
</div>
