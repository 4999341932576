import { Component, ElementRef, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent {
  layoutService = inject(LayoutService);
  el = inject<ElementRef<HTMLElement>>(ElementRef);
}
